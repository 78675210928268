/* stylelint-disable */
@import './variables.css';

.color_textator_primary { color: var(--textator_primary); }
.color_textator_primary_hover { color: var(--textator_primary_hover); }
.color_other_red { color: var(--other_red); }
.color_other_red_01 { color: var(--other_red_01); }
.color_other_red_02 { color: var(--other_red_02); }
.color_textator_primary_02 { color: var(--textator_primary_02); }
.color_textator_primary_01 { color: var(--textator_primary_01); }
.color_other_grey { color: var(--other_grey); }
.color_other_light_grey { color: var(--other_light_grey); }
.color_other_dark_grey { color: var(--other_dark_grey); }
.color_other_main_text { color: var(--other_main_text); }
.color_other_dark_grey_05 { color: var(--other_dark_grey_05); }
.color_other_dark_grey_02 { color: var(--other_dark_grey_02); }
.color_other_dark_grey_01 { color: var(--other_dark_grey_01); }
.color_other_dark_grey_006 { color: var(--other_dark_grey_006); }
.color_textator_primary_05 { color: var(--textator_primary_05); }
.color_other_white { color: var(--other_white); }
.color_other_black_01 { color: var(--other_black_01); }
.color_other_black_03 { color: var(--other_black_03); }
.color_other_orange { color: var(--other_orange); }
.color_other_success { color: var(--other_success); }
.color_other_success_01 { color: var(--other_success_01); }
.color_other_orange_01 { color: var(--other_orange_01); }
.color_current_color { /** It does not work through a variable in Edge */
color: currentColor; }

