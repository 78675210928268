.title-wrapper {
  padding: 0;
  display: flex;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  align-items: center;
}

.icon {
  margin-right: 8px;
  display: flex;
}

.title {
  width: 100%;
  text-align: left;
}
