.divider {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  transition: height 0.3s ease;
}

.divider__hover-zone {
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 12px;
}

.divider_hovered {
  background-color: var(--textator_primary_05);
}
