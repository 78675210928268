.compiler {
  position: relative;
  padding: 64px 10% 200px;
  width: 100%;
  text-align: left;
  background-color: var(--other_white);

  @mixin mobile {
    padding: 10px 0 200px;
  }

  @mixin desktop_small {
    max-width: 620px;
  }

  @mixin desktop_big {
    max-width: 880px;
  }

  @mixin hd {
    max-width: 880px;
  }
}

@media print {
  body {
    & a,
    & a:visited {
      color: blue;
    }
  }

  .compiler {
    overflow: visible;
  }
}

@media screen {
  .compiler__content:hover {
    cursor: pointer;
    outline: 1px solid black;
  }
}
