.trees-container {
  & > *:not(:last-child) {
    position: relative;
    margin-bottom: 12px;
    padding-bottom: 12px;

    &::after {
      position: absolute;
      z-index: 1;
      left: 6px;
      bottom: 0;
      width: calc(100% - 12px);
      height: 1px;
      content: '';
      background-color: var(--other_dark_grey_01);
    }
  }
}
