$font-family-sans:
  'Inter',
  // sans-serif
  'Helvetica',
  // sans-serif
  'Arial',
  // sans-serif
  -apple-system,
  blinkmacsystemfont,
  sans-serif;

$font-family-serif:
  'Lora',
  // serif
  'PT Serif',
  // serif
  'American Typewriter',
  // serif
  -apple-system,
  blinkmacsystemfont,
  serif;

.content {
  z-index: 1;
  padding: 0 10px;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  word-break: break-word;
  color: var(--other_dark_grey);

  & h1 {
    font-family: $font-family-serif;
    font-size: 32px;
    line-height: 51px;
  }

  & h2 {
    font-family: $font-family-serif;
    font-size: 24px;
    line-height: 38px;
  }

  & h3,
  & h4,
  & h5 {
    font-family: $font-family-serif;
    font-size: 18px;
    line-height: 29px;
  }

  & h6 {
    font-size: 16px;
    line-height: 18px;
  }

  & p {
    margin: 0;
    font-family: $font-family-serif;
    line-height: 26px;
  }

  & p:not(:last-of-type) {
    margin-bottom: 26px;
  }

  & ul {
    margin-top: 0;
    margin-bottom: 10px;
  }

  & code {
    padding: 10px 20px;
    display: block;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.1em;
    background-color: var(--other_light_grey);
    border-radius: 4px;
  }

  & em {
    font-style: italic;
    line-height: 18px;
  }

  & strong {
    font-weight: 700;
    line-height: 18px;
  }
}

.content_clickable {
  cursor: pointer;
}
