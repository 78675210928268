$font-family-sans:
  'Inter',
  // sans-serif
  'Helvetica',
  // sans-serif
  'Arial',
  // sans-serif
  -apple-system,
  blinkmacsystemfont,
  sans-serif;

.header {
  padding: 8px 16px;
  display: flex;
  background-color: var(--other_light_grey);
  align-items: center;

  @media print {
    display: none;
  }

  @mixin mobile {
    background-color: var(--other_white);
    border-bottom: 1px solid var(--other_dark_grey_01);
  }

  &__before {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    padding-right: 24px;
    font-family: $font-family-sans;
    font-weight: normal;
    color: var(--other_dark_grey);
    flex-grow: 1;

    & * {
      font-size: 18px;
      line-height: 150%;

      @mixin desktop {
        font-size: 24px;
      }
    }
  }

  &__kebab {
    position: relative;
  }

  &__after {
    display: flex;
  }

  &__after + &__kebab {
    margin-left: 16px;
  }

  &__ending {
    display: flex;
  }

  &__kebab + &__ending,
  &__after + &__ending {
    margin-left: 16px;
  }
}
