.toggler {
  &_absolute {
    position: absolute;
    top: 8px;
    right: 16px;
    height: auto;
  }

  &_closed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
