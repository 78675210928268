/* stylelint-disable no-descending-specificity */
.standard,
.shapeless {
  &.component-wrapper {
    width: 100%;
    font-size: 14px;
    color: var(--other_dark_grey);
  }

  .label {
    margin-bottom: 8px;
    display: inline-block;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    cursor: text;
    align-items: center;
  }

  .input {
    width: 100%;
    font-size: 1rem;
    color: var(--other_dark_grey);
    background-color: transparent;
    border: none;
    outline: none;

    @mixin not_mobile {
      font-size: inherit;
    }

    &::placeholder {
      color: var(--other_grey);
    }
  }

  .caption {
    margin-top: 8px;
    display: flex;
    align-items: center;

    &__icon {
      padding-right: 8px;
    }

    &__text {
      font-size: 12px;
    }
  }

  &.disabled {
    color: var(--other_grey);

    .input {
      background-color: var(--other_dark_grey_01);
    }
  }

  &.error {
    .caption {
      color: var(--other_red);

      .caption__icon svg {
        color: var(--other_red);
      }
    }
  }
}

.standard {
  .input {
    padding: 12px 16px;
    height: 44px;
    border: 1px solid var(--other_dark_grey_02);
    border-radius: 8px;

    &:focus {
      border: 1px solid var(--textator_primary);
    }
  }

  .left-icon {
    position: absolute;
    top: 12px;
    left: 16px;
    pointer-events: none;

    & + .input {
      padding-left: 44px;
    }
  }

  .end-icon {
    position: absolute;
    top: 12px;
    right: 16px;
  }

  .input-wrapper:has(.end-icon) .input {
    padding-right: 44px;
  }

  &.error {
    .input {
      border-color: var(--other_red);
    }
  }
}

.shapeless {
  .input {
    padding: 0;
    overflow: hidden;
    min-height: 24px;

    white-space: nowrap;
    text-overflow: ellipsis;

    :focus {
      text-overflow: clip;
    }
  }

  &.disabled {
    .input {
      border-radius: 8px;
    }
  }

  .left-icon {
    margin-right: 4px;
  }

  .end-icon {
    margin-left: 4px;
  }
}
