.progress-bar {
  display: flex;
  width: 100%;
  height: 6px;
  background: var(--textator_primary_05);
  border-radius: 8px;
}

.progress-bar_past {
  background-color: var(--textator_primary);
  border-radius: 8px;
}
