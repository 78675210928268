.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input {
  position: absolute;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  border: 0;
  clip-path: inset(100%);
}

.checkbox {
  position: relative;
  display: flex;
}

.checkbox_checked {
  display: none;
}

.input:checked ~ .checkbox_checked {
  display: flex;
}

.input:checked ~ .checkbox_unchecked {
  display: none;
}

.input:disabled ~ .checkbox {
  cursor: not-allowed;
  opacity: 0.4;
}

.input:disabled ~ .checkbox::before {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  content: '';
  background-color: var(--other_dark_grey_02);
  border-radius: 4px;
}

.label {
  margin-left: 8px;
}
