.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.splash-screen__logo {
  width: 250px;
}

.splash-screen__loading {
  position: relative;
}

.splash-screen__loading::before {
  position: absolute;
  width: 20px;
  height: 100%;
  content: '';
  background-color: white;
  transform: skewX(-21deg);
  animation: reflection 2s infinite;
}

@keyframes reflection {
  from {
    left: -30px;
  }

  to {
    left: calc(100% + 10px);
  }
}
