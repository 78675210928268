.item {
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%; /* 24px */
}

.item_selected {
  color: var(--textator_primary);
}

.item_focused {
  color: var(--textator_primary);
}

.item_hovered {
  color: var(--textator_primary_hover);
}

.item_disabled {
  color: var(--other_dark_grey_05);
}

.item-children {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  line-height: 150%;

  [slot='description'] {
    font-size: smaller;
  }
}

.item-first {
  display: flex;
  align-items: center;
}

.item-check {
  margin-right: 8px;
  display: flex;
  width: 16px;
  height: 16px;
}

[aria-expanded='true'] .item-check,
[aria-expanded='false'] .item-check {
  display: none;
}

.item__children-wrapper {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}
