.button {
  padding: 0;
  display: flex;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%; /* 24px */
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
}

.button-before {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.angle {
  margin-left: 8px;
  display: flex;
}
