.logo {
  display: flex;
  align-items: center;
  height: 40px;
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  gap: 12px;

  img {
    height: 36px;
  }
}
