.drawer-sidebar {
  position: fixed;
  z-index: var(--zindex-fixed);
  top: 0;
  left: 0;
  bottom: 0;

  &_opened {
    width: 100vw;
    height: 100dvh;
  }
}

.drawer-sidebar__children {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 548px;
  max-width: 100vw;
  height: 100%;
  background-color: var(--other_light_grey);

  transition: transform 0.2s;

  @mixin mobile_small {
    max-width: calc(100vw - 24px);
  }

  @mixin mobile_big {
    max-width: 424px;
  }
}

.drawer-sidebar__overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100dvh;
  pointer-events: auto;
  background-color: var(--other_black_01);
  opacity: 0;
  transition: opacity 0.2s;
}

.drawer-sidebar_left .drawer-sidebar__children {
  left: 0;
  transform: translateX(-100%);
}

.drawer-sidebar_right .drawer-sidebar__children {
  right: 0;
  transform: translateX(100%);
}

.drawer-sidebar_opened .drawer-sidebar__children {
  transform: translateX(0);
}

.drawer-sidebar_opened .drawer-sidebar__overlay {
  opacity: 1;
}

.drawer-sidebar_left .drawer-sidebar__overlay {
  right: 0;
}

.drawer-sidebar_right .drawer-sidebar__overlay {
  left: 0;
}
