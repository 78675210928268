.footer {
  position: relative;
  margin-top: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 1px;
    content: '';
    background-color: var(--other_dark_grey_01);
  }

  &_compressed {
    &::before {
      left: 0;
      width: 100%;
    }
  }

  &_opened {
    &::before {
      left: 16px;
      width: calc(100% - 32px);
    }
  }
}
