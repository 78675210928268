:root {
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-offcanvas: 1050;
  --zindex-modal: 1060;
  --zindex-popover: 1070;
  --zindex-tooltip: 1080;
}
