.compiler-content-block {
  position: relative;
  margin-top: 32px;
  padding: 0;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.compiler-content-block_view-mode {
  &:hover::before {
    position: absolute;
    top: 0;
    left: -5px;
    width: 5px;
    height: 100%;
    content: '';
    background-color: hsl(0deg 0% 76% / 50%);

    @mixin not_desktop {
      left: 0;
      background-color: hsl(0deg 0% 76% / 100%);
    }
  }
}
