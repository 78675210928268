.tree {
  position: relative;

  & & {
    padding-left: 27px;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 20px;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--other_dark_grey_01);
    }
  }
}
