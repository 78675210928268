.popover-content {
  z-index: var(--zindex-popover);
  padding: 12px 8px;
  box-sizing: border-box;
  width: max-content;
  max-width: 320px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 var(--other_dark_grey_01);

  @mixin not_mobile {
    max-width: 380px;
  }

  &_without-paddings {
    padding: 0;
  }
}
