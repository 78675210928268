.modal {
  position: fixed;
  z-index: var(--zindex-modal);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--other_black_03);
  justify-content: center;
  align-items: center;

  &__content-wrapper {
    position: relative;
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    color: var(--other_dark_grey);
    background-color: var(--other_white);
    border-radius: 8px;
    justify-content: center;
    box-shadow: 0 4px 20px var(--other_black_01);

    @mixin not_mobile {
      padding: 24px;
      width: auto;
      min-width: 520px;
      max-width: 560px;
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__veil {
    pointer-events: none;
    opacity: 0;
  }

  &__title {
    padding-right: 32px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  &__subtitle,
  &__content {
    font-size: 16px;
    line-height: 24px;
  }

  &__subtitle {
    margin-top: 16px;
  }

  &__content {
    margin-top: 16px;
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 12px;

    @mixin mobile {
      button {
        width: 100%;
      }
    }
  }
}
