.preview-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  @mixin not_mobile {
    flex-direction: row;
  }
}

.preview-page__playground-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--other_light_grey);

  @mixin mobile {
    padding-bottom: 88px;
  }
}

.preview-page__header {
  width: 100%;
}

.preview-page__content {
  position: relative;
  margin: 0 auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));
  background-color: var(--other_white);
  border-radius: 8px 0px 0px 0px;
  box-shadow: -2px 4px 10px rgba(46, 45, 90, 0.05);
  flex-grow: 1;
  align-items: center;
}

.highlighted {
  cursor: pointer;
  background-color: rgba(255, 181, 65, 0.4);
}
