.standard {
  position: relative;
  padding: 12px;
  display: flex;
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  align-items: center;

  &__avatar {
    position: relative;
    padding: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
      position: absolute;
      width: 32px;
      height: 32px;
      content: '';
      border: 1px solid var(--other_dark_grey);
      border-radius: 50%;
    }
  }

  &__name {
    margin-left: 12px;
    display: inline-block;
  }

  &__angle {
    position: absolute;
    top: 50%;
    right: 16px;
    display: inline-flex;
    transform: translateY(-50%);
  }
}

.compressed-primary {
  padding: 12px 16px;
  display: flex;
  background-color: var(--textator_primary_01);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.user-icon {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
}
