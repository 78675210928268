.list {
  position: relative;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  background-color: var(--other_white);
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 var(--other_black_01);
  align-items: flex-start;
  gap: 4px;

  &_without-shadow {
    box-shadow: none;
  }
}
