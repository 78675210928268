/* stylelint-disable scss/at-rule-no-unknown */

@define-mixin hd {
  @media (min-width: 1600px) {
    @mixin-content;
  }
}

@define-mixin desktop {
  @media (min-width: 1024px) {
    @mixin-content;
  }
}

@define-mixin desktop_big {
  @media (min-width: 1280px) and (max-width: 1599px) {
    @mixin-content;
  }
}

@define-mixin desktop_small {
  @media (min-width: 1024px) and (max-width: 1279px) {
    @mixin-content;
  }
}

@define-mixin tablet {
  @media (min-width: 721px) and (max-width: 1023px) {
    @mixin-content;
  }
}

@define-mixin mobile {
  @media (min-width: 320px) and (max-width: 720px) {
    @mixin-content;
  }
}

@define-mixin mobile_big {
  @media (min-width: 480px) and (max-width: 720px) {
    @mixin-content;
  }
}

@define-mixin mobile_small {
  @media (min-width: 320px) and (max-width: 479px) {
    @mixin-content;
  }
}

@define-mixin not_desktop {
  @media (min-width: 320px) and (max-width: 1024px) {
    @mixin-content;
  }
}

@define-mixin not_mobile {
  @media (min-width: 721px) {
    @mixin-content;
  }
}

@define-mixin hover {
  @media (hover: hover) {
    @mixin-content;
  }
}
