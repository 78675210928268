.item {
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  justify-content: flex-start;
}
