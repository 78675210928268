.button {
  padding: 4px 8px;
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 150%;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;

  @mixin hover {
    &:hover {
      background-color: var(--other_dark_grey_01);
    }
  }

  &:active {
    background-color: var(--other_dark_grey_01);
  }

  /* hack to redefine gaps for endIcon of Button component */
  && > div:last-child {
    margin-left: auto;
    padding-left: 32px;
  }

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
