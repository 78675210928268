.breadcrumbs-trigger {
  padding: 4px;
  display: flex;
  width: 28px;
  height: 28px;
  background-color: var(--other_dark_grey_01);
  border-radius: 8px;
  align-items: flex-end;
  justify-content: center;

  @mixin not_mobile {
    width: 36px;
  }
}

.breadcrumbs-link {
  text-decoration: none;
  color: inherit;
}
