@import 'normalize.css';
@import './zIndexes/zindexes.css';

$font-family-sans:
  'Inter',
  // sans-serif
  'Helvetica',
  // sans-serif
  'Arial',
  // sans-serif
  -apple-system,
  blinkmacsystemfont,
  sans-serif;

$font-family-serif:
  'Lora',
  // serif
  'PT Serif',
  // serif
  'American Typewriter',
  // serif
  -apple-system,
  blinkmacsystemfont,
  serif;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  font-family: $font-family-sans;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: var(--other_main_text);
  background-color: var(--other_white);
}

textarea {
  font-family: $font-family-serif;
  color: var(--other_dark_grey);
}

button {
  font-family: $font-family-sans;
  color: var(--other_dark_grey);
}

h1,
h2,
h3 {
  font-family: $font-family-serif;
}

h4 {
  font-family: $font-family-sans;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
}

hr {
  height: 1px;
  border: 0;
  border-bottom: solid 1px var(--other_light_grey);
}

address {
  font-style: normal;
  line-height: 1.5;
}

pre {
  white-space: pre-wrap;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.reset-button {
  margin: 0;
  padding: 0;
  overflow: visible;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* inherit font & color from ancestor */
  color: inherit;

  background: transparent;
  border: none;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

@media screen and (max-width: 667px) {
  h1 {
    font-size: 1.25rem;

    /* 20px */
  }
}

/*
 * Clearfix for floats
 */
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}

.clearfix::after {
  clear: both;
}
