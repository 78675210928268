$pop-up-width: 300px;

.auth {
  position: relative;
}

.auth__user {
  padding: 16px;
  display: flex;
  border-top: 1px solid var(--other_dark_grey_01);
  align-items: center;
  justify-content: space-between;
}

.auth__user-info {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 1;
  column-gap: 12px;
}

.auth__user-icon {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  border: 1px solid var(--other_dark_grey);
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.auth__user-name {
  white-space: nowrap;
}

.status-panel {
  z-index: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: $pop-up-width;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.status-panel_bottom {
  position: absolute;
  left: 5px;
  bottom: calc(100% + 10px);

  @mixin mobile {
    position: fixed;
    z-index: var(--zindex-fixed);
    top: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border: none;
  }
}

.status-panel__cross {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  width: 40px;
  height: 40px;

  @mixin mobile {
    display: flex;
  }
}

.auth__wrapper {
  gap: 8px;
  width: 100%;
}

.auth__button {
  width: 100%;
}

.auth__button:not(:last-of-type) {
  margin-bottom: 10px;
}
