.container {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 16px;

  &_active {
    background-color: var(--other_dark_grey_006);
    border-radius: 8px;
  }
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &__arrow {
    margin-right: 12px;
  }
}
