.button-base {
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
}

.button-standard {
  padding: 8px 16px;
  min-height: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%; /* 21px */
  text-decoration: unset;
  border-radius: 8px;
  outline: 1px solid transparent;

  &:disabled {
    color: var(--other_dark_grey_05);
    background-color: var(--other_dark_grey_01);
  }
}

.button-size_s {
  height: 24px;
  font-size: 12px;
}

.button-size_m {
  height: 32px;
  font-size: 14px;
}

.button-size_l {
  height: 40px;
  font-size: 16px;
}

.button-shapeless {
  padding: 0;
  height: auto;
  background: none;
  border: none;
}

:where(.button-base) > .left-icon:not(:last-child) {
  margin-right: 8px;
}

:where(.button-base) > .end-icon:not(:first-child) {
  margin-left: 8px;
}

/* stylelint-disable-next-line no-descending-specificity */
.button-standard,
.button-shapeless {
  .left-icon,
  .end-icon {
    display: flex;
    align-items: center;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }
}

.button_primary {
  color: white;
  background-color: var(--textator_primary);

  @mixin hover {
    &:hover {
      background-color: var(--textator_primary_hover);
    }
  }

  &:active {
    outline: 1px solid var(--textator_primary);
  }
}

.button_secondary {
  color: var(--textator_primary);
  background-color: var(--textator_primary_01);

  @mixin hover {
    &:hover {
      background-color: var(--textator_primary_02);
    }
  }

  &:active {
    background-color: var(--textator_primary_02);
    outline: 1px solid var(--textator_primary);
  }
}

.button_thirdary {
  color: var(--other_dark_grey);
  background-color: var(--other_dark_grey_006);

  @mixin hover {
    &:hover {
      background-color: var(--other_dark_grey_02);
    }
  }

  &:active {
    background-color: var(--other_dark_grey_02);
    outline: 1px solid var(--other_dark_grey_02);
  }
}

.button_error {
  color: var(--other_red);
  background-color: var(--other_red_02);

  @mixin hover {
    &:hover {
      background-color: var(--other_red_02);
    }
  }

  &:active {
    background-color: var(--other_red_02);
    outline: 1px solid var(--other_red);
  }
}
