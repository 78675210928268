.standard-sidebar {
  --transition-delay: 0.3s;
  --header-height: 64px;
  --dynamic-sidebar-top: calc(var(--header-height) + 24px);
  --dynamic-sidebar-height: calc(100% - var(--header-height) - 56px);
  padding-left: 0;

  height: 100dvh;
  transition: padding-left 0.2s;
}

.standard-sidebar_opened {
  padding-left: 356px;
}

.standard-sidebar__navigation {
  position: absolute;
  z-index: var(--zindex-fixed);
  top: 88px; /* --dynamic-sidebar-top */
  left: 0;
  display: flex;
  flex-direction: column;
  width: 356px;

  height: calc(100% - 64px - 56px); /* --dynamic-sidebar-height */
  background-color: var(--other_light_grey);
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  transition-delay: 0.3s; /* --transition-delay */
  transform: translate3d(-100%, 0, 0);
}

.standard-sidebar__navigation_opened {
  top: 0;
  height: 100%;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.standard-sidebar__navigation_opened:hover {
  && {
    box-shadow: none;
  }
}

.standard-sidebar__navigation:hover,
.standard-sidebar__overlay:hover ~ .standard-sidebar__navigation {
  top: 88px; /* --dynamic-sidebar-top */
  height: calc(100% - 64px - 56px); /* --dynamic-sidebar-height */
  box-shadow: 8px 0 40px 0 var(--other_black_01);
  transform: translate3d(0, 0, 0);
}

.standard-sidebar__overlay {
  position: absolute;
  z-index: 1;

  top: 88px; /* --dynamic-sidebar-top */
  left: 0;
  width: 24px;
  height: calc(100% - 64px - 56px); /* --dynamic-sidebar-height */
  background-color: transparent;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: var(--textator_primary_02);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: transform 0.2s;
    transform: translate3d(-100%, 0, 0);

    @mixin mobile {
      display: none;
      content: none;
    }
  }

  &:hover::before {
    transform: translate3d(0, 0, 0);
  }
}
