.embedded-auth {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 600px;
  background-color: white;

  @mixin mobile {
    width: 100vw;
    height: 100dvh;
  }
}

.embedded-auth__iframe {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embedded-auth__loader-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--other_white);
  align-items: center;
  justify-content: center;
}
