/* stylelint-disable */

:root {
  --textator_primary: rgba(114,140,241,1);
  --textator_primary_hover: rgba(91,117,215,1);
  --other_red: rgba(238,109,108,1);
  --other_red_01: rgba(238,109,108,0.1);
  --other_red_02: rgba(238,109,108,0.2);
  --textator_primary_02: rgba(114,140,241,0.2);
  --textator_primary_01: rgba(114,140,241,0.1);
  --other_grey: rgba(109,123,146,1);
  --other_light_grey: rgba(248,247,250,1);
  --other_dark_grey: rgba(70,85,109,1);
  --other_main_text: rgba(46,45,90,1);
  --other_dark_grey_05: rgba(70,85,109,0.5);
  --other_dark_grey_02: rgba(70,85,109,0.2);
  --other_dark_grey_01: rgba(70,85,109,0.1);
  --other_dark_grey_006: rgba(70,85,109,0.05);
  --textator_primary_05: rgba(114,140,241,0.5);
  --other_white: rgba(255,255,255,1);
  --other_black_01: rgba(0,0,0,0.1);
  --other_black_03: rgba(0,0,0,0.3);
  --other_orange: rgba(238,139,108,1);
  --other_success: rgba(102,172,80,1);
  --other_success_01: rgba(102,172,80,0.1);
  --other_orange_01: rgba(238,139,108,0.1);
  --current_color: currentColor;
}
